import styled from 'styled-components';

const PocketWrapper = styled.div`
  padding: 40px 0;
  img {
    margin: 0 auto;
    padding: 0 5px;
  }
  .misc_contianer {
    box-shadow: 0 5px 35px rgba(0, 0, 0, 0.1);
    border-bottom-width: 3px;
    border-bottom-color: #0057a9;
    border-bottom-style: solid;
  }
  .misc_contianer:hover {
    box-shadow: 0 16px 64px 0 rgba(18, 21, 26, 0.24);
  }
`;

export default PocketWrapper;
