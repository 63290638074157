import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import Image from 'common/src/components/Image';
import Container from 'common/src/components/UI/Container';
import { useStaticQuery, graphql, Link } from 'gatsby';

import PocketWrapper from './pocketresource.style';

import ChecklistImg from 'common/src/assets/image/hosting/Pages/Misc/Checklist-for-AI-Automation-v2.jpg';
import FourStep from 'common/src/assets/image/hosting/Pages/Misc/Ultimate-4-step-guide-v2.jpg';
import Checklist from 'common/src/assets/image/hosting/Pages/Misc/Cheat-Sheet-Banner.jpg';
import benifits from 'common/src/assets/image/hosting/Pages/Infographics/6 Must Have Benefits of AI Automation.jpg';

const MiscellaneousSection = ({ row, col, title, textArea, description }) => {
  const Data = useStaticQuery(
    graphql`
      query {
        hostingJson {
          POCKET_RESOURCES {
            link
            title
            alt
            avatar {
              childImageSharp {
                fluid {
                  src
                }
              }
            }
          }
        }
      }
    `
  );
  return (
    <PocketWrapper>
      <Fade bottom cascade delay={5}>
        <Container>
          <Box {...row}>
            {Data.hostingJson.POCKET_RESOURCES.map((item, index) => (
              <Box {...col} key={index}>
                <Link to={`/pocketresources/${item.link}`}>
                  <Image
                    src={item.avatar.childImageSharp.fluid.src}
                    alt={`${item.alt}`}
                  />
                  {/* <Heading
                   {...title}
                   content="Ultimate 4 Step Guide to Identify IPA use Cases"
                 /> */}
                  <Heading {...description} content={item.title} />
                </Link>
              </Box>
            ))}
          </Box>
        </Container>
      </Fade>
    </PocketWrapper>
  );
};

MiscellaneousSection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  textArea: PropTypes.object,
  description: PropTypes.object,
};

MiscellaneousSection.defaultProps = {
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
    // justifyContent: 'flex-start',
    ml: '-15px',
    mr: '-15px',
  },
  col: {
    width: ['100%', '100%', '47%', '30%', '30%'],
    margin: '2% 1%',
    padding: '30px 15px 15px 15px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    className: 'misc_contianer',
  },
  textArea: {
    margin: '20px 15px',
    padding: '30px 15px',
    backgroundColor: '#fff',
    borderRadius: '5px',
    className: 'misc_contianer',
  },
  title: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    mb: '5px',
    pt: '15px',
    textAlign: 'center',
  },
  description: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#0f2137',
    letterSpacing: '0.015em',
    color: '#252525',
    mb: '0px',
    pt: '15px',
    textAlign: 'center',
  },
};

export default MiscellaneousSection;
